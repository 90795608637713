<!--
 * @Author: wangwei
 * @Date: 2020-12-29 15:56:16
 * @LastEditTime: 2021-01-27 12:24:12
 * @LastEditors: Please set LastEditors
 * @Description: 订单支付页面
 * @FilePath: /java-pc/src/views/buy/pay.vue
-->
<template>
    <div id="pay">
        <div class="top_info_header">
            <div class="top_logo">
                <div :style="`background-image:url(${configInfo.main_site_logo})`" :onerror='defaultImg'></div>
            </div>
            <div class="top_info_progress flex_row_end_center">

                <div class="progress_item" v-if="ifcart == 'true'">
                    <div class="progress flex_row_center_center">
                        <span class="active">1</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true }"></div>
                        </div>
                    </div>
                    <p>购物车</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == 'true' ? 2 : 1 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: true, current: false }"></div>
                        </div>
                    </div>
                    <p style="margin-left: -11px">确认订单</p>
                </div>

                <div class="progress_item">
                    <div class="progress flex_row_center_center">
                        <span class="active">{{ ifcart == 'true' ? 3 : 2 }}</span>
                        <div class="progress_line">
                            <div :class="{ content: true, active: payComplete }"></div>
                        </div>
                    </div>
                    <p>付款</p>
                </div>

                <div class="progress_item" style="margin-left: -24px">
                    <div class="progress flex_row_end_center">
                        <span :class="{ active: payComplete }">{{ ifcart == 'true' ? 4 : 3 }}</span>
                    </div>
                    <p>支付成功</p>
                </div>

            </div>
        </div>
        <div class="top_info">

            <div class="top_info_text flex_row_between_between">
                <span>订单提交成功，请您尽快付款！ 订单号： {{ pay_info.data.paySn }}</span>
                <div class="price">
                    应付金额
                    <span>{{ pay_info.data.needPay }}</span> 元
                </div>
            </div>
            <div class="top_info_text flex_row_between_between">
                <span>
                    请您在提交订单后
                    <span>24小时内</span>完成支付，否则订单会自动取消
                </span>
                <div class="show_detail pointer" @click="showDetail">{{ show_detail ? '收起详情' : '展开详情' }}</div>
            </div>
            <div class="receive_info">
                <p v-if="show_detail && pay_info.data.receiveAddress && pay_info.data.receiverName">
                    收货地址：{{ pay_info.data.receiveAddress }}
                    收货人：{{ pay_info.data.receiverName }}
                    {{ pay_info.data.receiverMobile }}</p>
                <p v-if="show_detail">商品名称：{{ buy_name_list }}</p>
            </div>
        </div>
        <div class="bg">
            <!-- 签署协议start -->
            <div class="pay_method bindCard" >

                <div class="bank_bottom_Form">
                    <UnBind></UnBind>
                    <!-- 绑定银行卡 start -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import UnBind from "./unbind.vue";
export default {
    name: "Pay",
    components: {
        ElInput,
        UnBind
    },
    setup() {
        // 签署协议
        const bankForm = ref({
            name: '',
            region: '',
            date1: '',
            date2: '',
            delivery: false,
            type: [],
            resource: '',
            desc: '',
        })
        let isBinding = ref(true)
        // 签署协议end
        const ifcart = ref('')
        const balance = require("../../assets/buy/balance.png");
        const otherPay = require("../../assets/buy/otherPay.png");
        const ali = require("../../assets/buy/ali.png");
        const wechat = require("../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../assets/buy/wx_pay_tip.png");
        const route = useRoute();
        const router = useRouter();
        const store = useStore()
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const buy_name_list = ref("");
        const password = ref("");
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref('');//微信支付二维码
        const timer = ref(0);//定时器
        const show_detail = ref(true)
        const hasPayMethod = ref(true)
        const configInfo = ref(store.state.configInfo)
        const memberInfo = ref(store.state.memberInfo);
        const defaultImg = ref('this.src="' + require('../../assets/common_top_logo.png') + '"')
        console.log(memberInfo.value)
        //获取支付方式
        const getPayMethod = () => {
            proxy
                .$get("v3/business/front/orderPay/payMethod", {
                    source: "pc",
                    type: 1
                })
                .then(res => {
                    if (res.state == 200) {
                        hasPayMethod.value = res.data.length > 0 ? true : false
                        res.data.map(item => {
                            if (item.payMethod == "balance") {
                                balance_list.data.push(item);
                            } else {
                                other_pay_list.data.push(item);
                            }
                        });
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //获取订单支付数据
        const getPayInfo = () => {
            proxy
                .$get("v3/business/front/orderPay/payInfo", {
                    paySn: route.query.paySn,
                    payFrom: route.query.payFrom
                })
                .then(res => {
                    if (res.state == 200) {
                        pay_info.data = res.data;
                        buy_name_list.value = res.data.goodsNameList.join(" + ");
                    } else if (res.state == 267) {
                        ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                        //清除定时器
                        if (timer.value) {
                            clearInterval(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const changePayMethod = pay_method => {
            current_pay_method.data = pay_method;
        };
        //去支付
        const payComplete = ref(false)
        const pay = () => {
            let param = {};
            if (memberInfo.value.memberUserType != 1) {
            if (!current_pay_method.data.payMethod) {
                ElMessage.warning("请选择支付方式");
                return;
            }
            
            param.payType = current_pay_method.data.payType;
            param.payMethod = current_pay_method.data.payMethod;
            param.paySn = pay_info.data.paySn;

            //余额支付
            if (param.payMethod == 'balance') {
                if (password.value == "") {
                    ElMessage.warning("请输入支付密码");
                    return;
                }
                param.payPwd = password.value; //支付密码,使用余额时必传
            }
        } else {
            console.log(1111111111111)
            if (!bankCode.value) {
                ElMessage.warning("请选择银行卡");
                return
            }
            // if (!bankNum.value) {
            //     ElMessage.warning("请输入银行卡号");
            //     return
            // }
            // 企业支付
            
            param.payType = 'B2B';
            param.payMethod = 'B2B';
            param.paySn = pay_info.data.paySn;
            param.bankCode= bankCode.value;
            // param.bankCardNo= '';
            console.log(param)
        }

            //请求支付接口
            proxy.$post("v3/business/front/orderPay/doPay", param).then(res => {
                if (res.state == 200) {
                    document.write(res.data.payData);
                    if (param.payMethod == 'balance') {
                        ElMessage.success('支付成功,2s后自动跳转订单列表');
                        payComplete.value = true
                        //清除定时器
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    } else if (param.payMethod == 'alipay') {
                        document.write(res.data.payData);//自动提交表单数据
                    } else if (param.payMethod == 'wx') {
                        show_wx_pay.value = true;
                        wxPayQrImg.value = 'data:image/png;base64,' + res.data.payData;//微信支付二维码
                        // 定时查询是否支付成功
                        timer.value = setInterval(() => {
                            queryPayState();
                        }, 3000)
                        //两小时自动跳转订单列表
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 432000000);
                    }
                } else {
                    ElMessage(res.msg);
                }
            })
                .catch(() => {
                    //异常处理
                });
        };
        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        }
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value
        }
        onMounted(() => {
            paySn.value = route.query.paySn;
            ifcart.value = route.query.ifcart
            getPayMethod();
            getPayInfo();
        });
        const goRecharge = () => {
            router.push('/member/recharge')
        }
        const forgetPassword = () => {
            router.push('/member/pwd/reset')
        }
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        // 获取银行列表
        const bankNum = ref() //银行卡号
        let bankList = ref([])
        const getBankList = () => {
            proxy.$get('api/pay/common/queryBankB2b').then(res => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg)
                        bankList.value = res.data
                        console.log(bankList.value)
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
        }
        getBankList()
        const ischecked = ref('')
        const bankCode = ref(null) //bankcode
        const changeBnak = (item) => {
            console.log(item.code)
            ischecked.value = item.code
            bankCode.value = item.code
        }


        return {
            pay_method,
            balance,
            otherPay,
            ali,
            wechat,
            pay_info,
            current_pay_method,
            balance_list,
            other_pay_list,
            changePayMethod,
            buy_name_list,
            password,
            pay,
            wx_pay_tip,
            show_wx_pay,
            hideWxPay,
            refreshWxQrcode,
            wxPayQrImg,
            goRecharge,
            forgetPassword,
            showDetail,
            show_detail,
            hasPayMethod,
            configInfo,
            defaultImg,
            memberInfo,
            payComplete,
        };
    },
};
</script>


<!-- 签署协议表单样式 -->
<style lang="scss">
.bank_bottom_Form {
    // .el-cascader {
    //     width: 100%;
    // }

    .el-input__suffix {
        top: -4px;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style scoped lang="scss">
.bindCard {
    height: 900px;
}
.bankInput {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -50px;
    left: 100px;
    span {
        margin-right: 10px;
        font-size: 14px;
    }
    .bankInput_con {
        height: 30px;
    }
}
.bankList {
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.activation {
    width: 133px;
    height: 49px;
    line-height: 49px;
    border-radius: 4px;
    background-color: rgba(240, 39, 39, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    margin-top: 85px;
}

.card_list {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;

    .item {
        position: relative;
        margin-right: 10px;
        margin-top: 30px;
    }

    .unbinding {
        position: absolute;
        top: -18px;
        right: 2px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../assets/member/chooseAll.png);
        background-size: contain;
    }
}

.bank_content {
    margin-left: 20px;
    .bank_content_top {
        height: 50px;
        line-height: 50px;
        color: #1C1C1C;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
    }

    .bank_content_bottom {
        width: 307px;
        height: 142px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: SourceHanSansSC-bold;
            font-weight: 600;
            margin-left: 20px;

            &:nth-child(2) {
                margin-top: -20px;
            }

        }
    }
}

.bank_bottom_Form {
    padding-left: 60px;
    position: relative;

    .timeOut {
        position: absolute;
        top: 5px;
        left: 330px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next {
        width: 90px;
        background-color: red;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        margin-left: 110px;
        margin-top: 40px;
    }
}
</style>
<style lang="scss">
@import "../../style/pay.scss";
@import "../../style/base.scss";

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}
</style>